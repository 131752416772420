@import '../../assets/css/colors';
@import '../../assets/css/layout';

.product-thumbnail {
  .product-thumbnail-image {
    background-color: $darkwhitesmoke;
    border-radius: 10px;
    padding-top: 100%;
    position: relative;

    .product-thumbnail-image-inner {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      transform: rotate(45deg) scale(.8);

      @media (min-width: $desktopSize) {
        transform: rotate(45deg);
      }

      img {
        position: relative;
        top: 50%;
        transform: translateY(-50%);
        width: 100%;
      }
    }
  }

  .product-thumbnail-text {
    .product-thumbnail-description {
      min-height: 60px;
      position: relative;

      p {
        position: absolute;
        top: 50%;
        left: 0;
        right: 0;
        transform: translateY(-50%);
      }
    }

    .icon {
      position: relative;
      top: -3px;
      width: 30px;
    }

    h5 {
      display: inline-block;
    }

    p {
      margin: auto;
      width: 90%;
    }
  }
}
