@import "colors";

html,
body {
  font-family: $raleway;
  font-size: 13px;
  font-weight: 600;
  letter-spacing: 0.03em;
}

a:not([href]):not([tabindex]),
a {
  color: inherit;
  cursor: url(../../assets/icon/cursor-link.svg) 20 20, auto;
  text-decoration: none;
  transition: color 0.3 ease;
  outline: 0;

  &:active,
  &:focus {
    outline: 0;
  }

  &:hover {
    color: inherit;
    text-decoration: none;
  }
}

p {
  font-size: 11px;
  line-height: 1.7rem;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 900;
  line-height: 1.4em;
}

h1 {
  font-size: 3rem;
}

h2 {
  font-size: 2.5rem;
}

h3 {
  font-size: 2rem;
}

pre {
  background-color: $black;
  border-radius: 7px;
  color: $white;
  margin: 10px 0;
  padding: 10px 15px;

  &.danger {
    background-color: darken($red, 5%);
  }
}

// ===================================
// Text Size
// ===================================

.text-smaller {
  font-size: 11px;
}

.text-bigger {
  font-size: 15px;
}

// ===================================
// Text Weight
// ===================================

.text-bold {
  font-weight: 600;
}

.text-regular {
  font-weight: 400;
}

.text-thin {
  font-weight: 300;
}

// ===================================
// Text Display
// ===================================

.text-block {
  margin: auto;
  max-width: 400px;
}

.text-inline-block {
  display: inline-block;
}

.text-inline {
  display: inline;
}

// ===================================
// Text/Link Style
// ===================================

.text-uppercase {
  text-transform: uppercase;
}

.link-underlined,
.text-underlined {
  border-bottom: 2px solid $black;
  padding-bottom: 5px;
}

.link-underlined {
  transition: all 0.3s ease;

  &:hover {
    border-bottom-color: $red;
    color: $red;
  }
}

.text-italic {
  font-style: italic;
}

// ===================================
// Text Alignment
// ===================================

.text-left {
  text-align: left;
}

.text-right {
  text-align: right;
}

.text-center {
  text-align: center;
}

// ===================================
// Text/Link Color
// ===================================

.link-blue,
.text-blue {
  color: $blue;
  transition: color 0.3s ease;
}

.link-blue {
  &:hover {
    color: darken($blue, 10%);
  }
}

.link-green,
.text-green {
  color: $green;
  transition: color 0.3s ease;
}

.link-green {
  &:hover {
    color: darken($green, 10%);
  }
}

.link-yellow,
.text-yellow {
  color: $yellow;
  transition: color 0.3s ease;
}

.link-yellow {
  &:hover {
    color: darken($yellow, 10%);
  }
}

.link-red,
.text-red {
  color: $red;
  transition: color 0.3s ease;
}

.link-red {
  &:hover {
    color: darken($red, 10%);
  }
}

.text-muted,
.link-gray,
.text-gray {
  color: $darkgray;
  transition: color 0.3s ease;
}

.link-gray {
  &:hover {
    color: $black;
  }
}
