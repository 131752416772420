@import '../../assets/css/colors';

.pagination {
  li {
    button {
      background: none;
      border: 2px solid $darkgray;
      color: $darkgray;
      margin-right: 10px;
      padding: 5px 12px;
    }

    &.active {
      button {
        border-color: $black;
        color: $black;
      }
    }
  }
}
