@import '../../assets/css/layout';

.zodiac-profile-container {
  .zodiac-profile-image {
    padding: 0 30px;
    min-height: 450px;

    @media (min-width: $tabletSize) {
      padding: 0;
    }

    img {
      width: 100%;
      height: 100%;
    }
  }

  @media (min-width: $tabletSize) {
    .zodiac-profile-description {
      position: relative;
      height: 100%;

      .zodiac-profile-description-inner {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
      }
    }
  }

  .zodiac-profile-feature {
    margin-bottom: 30px;

    h4 {
      hyphens: auto;
    }
  }
}
