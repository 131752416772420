@import '../../assets/css/colors';

.ads-container {
  background-color: $darkwhitesmoke;
  text-align: center;
  padding: 30px;

  img {
    width: 100%;
    height: 100%;
  }
}
