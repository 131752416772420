@import '../../assets/css/colors';
@import '../../assets/css/layout';

footer {
  background-color: $white;
  padding: 60px 0;

  .logo {
    img {
      display: inline-block;
      margin-bottom: 15px;
    }
  }

  .footer-text {
    margin: 30px 0;

    .footer-newsletter {
      margin: 30px 0;
    }

    .footer-nav {
      margin: 30px 0;

      li {
        margin-bottom: 20px;
      }
    }

    .footer-social {
      margin: 30px 0;

      li {
        display: inline-block;
        margin-right: 10px;

        a {
          font-size: 18px;
        }
      }
    }

    .footer-copyright {
      margin: 30px 0;
    }
  }
}
