@import '../../assets/css/colors';
@import '../../assets/css/layout';

.product-specimen {
  position: relative;

  .product-specimen-text {
    display: inline-block;
    background-color: $white;
    padding: 60px 30px;
    width: 100%;

    @media (min-width: $tabletSize) {
      width: 30%;
    }
  }

  .product-specimen-image {
    background-color: $darkwhitesmoke;
    min-height: 400px;
    position: relative;
    width: 100%;

    img {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%) scale(1.2);

      @media (min-width: $tabletSize) {
        transform: translate(-50%, -50%) scale(1.5);
      }
    }

    @media (min-width: $tabletSize) {
      display: inline-block;
      position: absolute;
      top: 0;
      bottom: 0;
      width: 70%;

      img {
        position: relative;
      }
    }
  }

  .product-specimen-button {
    margin-top: 120px;
  }
}
