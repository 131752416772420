.user-badge {
  vertical-align: middle;

  .user-image {
    border-radius: 100%;
    margin: 0 10px;
    width: 40px;
    height: 40px;
  }
}
