@import '../../assets/css/colors';
@import '../../assets/css/layout';

.zodiac-scrollbar-container {
  margin: 90px 0;
  margin-left: 15vw;
  overflow-x: scroll;
  white-space: nowrap;

  @media (min-width: $tabletSize) {
    margin-left: 20vw;
  }

  .zodiac-scrollbar {
    list-style: none;
    padding: 0;

    li {
      display: inline-block;
      margin-right: 7vw;

      button {
        background: none;
        border: none;
        color: $lightgray;
        font-size: 40px;
        font-weight: 900;
        outline: none;
        padding: 0;
        transition: all .3s ease;

        &.active {
          color: $black;
        }

        @media (min-width: $tabletSize) {
          font-size: 60px;
        }
      }
    }
  }
}
