@import "../../assets/css/layout";

.entry {
  p {
    img {
      width: 100%;
      margin: 20px 0;
    }
  }

  .entry-date {
    margin: 0 20px;
  }

  .entry-image-landscape {
    margin: 0;
    padding-top: 100%;

    @media (min-width: $tabletSize) {
      margin: 30px 0;
      padding-top: 65.5%;
    }
  }
}
