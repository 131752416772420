.collection {
  .collection-image {
    display: inline-block;
    width: 70%;

    img {
      width: 100%;
      height: 100%;
    }
  }

  .collection-text {
    display: inline-block;
    position: relative;
    width: 30%;

    .collection-text-inner {
      position: absolute;
      top: 50%;
      left: -50%;
      right: 0;
      transform: translateY(-50%);

      .collection-button {
        margin-top: 120px;
      }
    }
  }
}
