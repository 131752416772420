button {
  cursor: url(../../assets/icon/cursor-link.svg) 20 20, auto;
  outline: none;

  &:focus {
    outline: none;
  }

  &:active {
    color: $black;
  }
}

[type="button"]:not(:disabled),
[type="reset"]:not(:disabled),
[type="submit"]:not(:disabled),
button:not(:disabled) {
  cursor: url(../../assets/icon/cursor-link.svg) 20 20, auto;
}

.btn-anchor {
  background-color: transparent;
  border: none;
  padding-top: 0;
  padding-bottom: 0;
  text-align: inherit;

  &:hover,
  &:focus {
    text-decoration: none;
  }

  &.btn-full {
    display: inline-block;
    width: 100%;
  }

  img {
    cursor: url(../../assets/icon/cursor-link.svg) 20 20, auto;
  }
}

.btn {
  border-bottom: 2px solid $black;
  border-radius: 0;
  color: $black;
  display: inline-block;
  font-weight: 600;
  min-width: 100px;
  padding: 10px 20px;
  transition: all 0.3s ease;
  outline: 0;

  &:active,
  &:focus {
    outline: 0;
  }

  &:hover {
    border-bottom-color: $red;
    color: $red;
    padding: 10px 30px;
  }

  &.btn-left {
    padding: 10px 60px 10px 20px;

    &:hover {
      padding: 10px 30px;
    }
  }

  &.btn-right {
    padding: 10px 20px 10px 60px;

    &:hover {
      padding: 10px 30px;
    }
  }

  &.btn-white {
    border-bottom-color: $white;
    color: $white;
  }
}
