textarea,
select,
input {
  cursor: url(../../assets/icon/cursor-type.svg) 20 20, auto;

  &:focus {
    outline: none;
  }
}

select,
.text-input {
  background-color: transparent;
  border: none;
  border-radius: 0;
  box-sizing: border-box;
}

.field {
  position: relative;
  margin-bottom: 2em;

  &.field-inline {
    display: inline-block;
    width: 50%;

    &:nth-child(2) {
      float: right;
    }
  }

  select,
  .text-input {
    border-bottom: 1px solid rgba($darkgray, .2);
    position: relative;
    padding: 3px 0;
    transition: border .1s ease;
    width: 100%;
    z-index: 1;

    &:focus {
      border-color: $black;
    }

    &::placeholder {
      color: transparent;
    }

    &:not(:placeholder-shown) ~ label,
    &:focus ~ label {
      padding-top: calc(var(--input-padding-y) / 3);
      padding-bottom: calc(var(--input-padding-y) / 3);
      font-size: 10px;
      top: -15px;
      opacity: 1;
    }
  }

  select {
    appearance: none;
    padding: 3px 5px;
  }

  textarea {
    border: 1px solid rgba($darkgray, .2);
    border-radius: 4px;
    padding: 15px;
    width: 100%;
    min-height: 150px;
  }

  .icon {
    display: inline-block;
    position: absolute;
    right: 5px;
    top: 5px;
  }

  label {
    color: rgba($darkgray, .5);
    font-weight: 400;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 0;
  }

  > label {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    transition: all .1s ease-in-out;
  }
}

.long-field {
  border-bottom: 1px solid rgba($darkgray, .2);
  position: relative;
  width: 100%;
  margin-top: 10px;
  padding: 5px 0;

  label {
    display: inline-block;
    width: 40%;
  }

  .text-input {
    display: inline-block;
    width: 100%;
  }
}

.checkbox {
  display: flex;
  align-items: center;
  margin: 10px 0;
  user-select: none;

  label {
    cursor: url(../../assets/icon/cursor-link.svg) 20 20, auto;
    position: absolute;
    padding-left: 25px;
    z-index: 2;
  }

  input {
    opacity: 0;
    visibility: hidden;
    position: absolute;

    &:checked {
      ~ .check {
        border-color: $green;
        box-shadow: 0px 0px 0px 15px $green inset;

        &::after {
          opacity: 1;
          transform: scale(1);
        }
      }
    }
  }

  .check {
    width: 20px;
    height: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    border-radius: 100px;
    background-color: $white;
    border: 2px solid rgba($darkgray, .3);
    box-shadow: 0px 0px 0px 0px $darkgray inset;
    transition: all .15s cubic-bezier(0, 1.05, .72, 1.07);

    &::after {
      content: '';
      width: 100%;
      height: 100%;
      opacity: 0;
      z-index: 1;
      position: absolute;
      transform: scale(0);
      background-size: 50%;
      background-image: url('../icon/checkmark.svg');
      background-repeat: no-repeat;
      background-position: center;
      transition-delay: .2s !important;
      transition: all .25s cubic-bezier(0, 1.05, .72, 1.07);
    }
  }

  &.checkbox-red {
    input {
      &:checked {
        ~ .check {
          border-color: $red;
          box-shadow: 0px 0px 0px 15px $red inset;
        }
      }
    }
  }
}
