@import '../../assets/css/layout';

.notfound-section {
  margin-top: 150px;
  text-align: center;

  @media (min-width: $tabletSize) {
    .notfound-title {
      display: none;
    }
  }

  .notfound-artwork {
    width: 100%;
    height: 100%;
  }
}