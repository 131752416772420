@import '../../assets/css/colors';
@import '../../assets/css/layout';

.contact-section {
  .header {
    background-color: $white;
    min-height: 1000px;

    .header-image {
      display: none;

      @media (min-width: $tabletSize) {
        background-size: contain;
        display: block;
        left: 50%;
        right: 5%;
      }
    }

    .header-text {
      .header-text-inner {
        left: 10%;
      }
    }
  }
}