@import '../../assets/css/colors';
@import '../../assets/css/layout';

.navbar-container {
  .navbar {
    display: block;
    padding: 30px 15px;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    z-index: 2;

    @media (min-width: $tabletSize) {
      padding: 30px;
    }

    &.active {
      position: fixed;
    }

    .logo {
      display: inline-block;
      position: absolute;
      top: 30px;
      right: 30px;

      @media (min-width: $tabletSize) {
        right: 50%;
        transform: translateX(50%);
      }

      .logo-white {
        display: none;
      }
    }

    .menu-toggler {
      appearance: none;
      background: none;
      border: none;
      box-sizing: content-box;
      cursor: url(../../assets/icon/cursor-link.svg) 20 20, auto;
      display: inline-block;
      outline: none;
      width: 20px;
      height: 20px;
      vertical-align: middle;

      div {
        background-color: $black;
        height: 2px;
        margin-top: 5px;
        margin-bottom: 7px;
        transition: all .3s ease;

        &:last-child {
          width: 70%;
        }
      }

      &.active {
        div {
          margin: 0;
          transform: rotate(45deg) translateY(1px);

          &:last-child {
            width: 100%;
            transform: rotate(-45deg) translateY(-1px);
          }
        }
      }
    }

    .nav-right,
    .nav-left {
      display: block;
      list-style: none;
      margin: 60px 0;
      padding: 0 15px;
      opacity: 0;
      transition: opacity .3s ease-in-out;

      @media (min-width: $tabletSize) {
        display: inline-block;
        margin: 0 15px;
        padding: 0;
        opacity: 1;
      }

      &.active {
        opacity: 1;
      }
    }

    @media (min-width: $tabletSize) {
      .nav-left {
        &.active {
          opacity: 0;
        }

        li {
          &:nth-child(n+4) {
            display: none;
          }
        }
      }
    }

    .nav-right {
      li {
        button {
          width: 100%;

          .alt-text {
            float: right;
            font-weight: 600;
          }
        }
      }

      @media (min-width: $tabletSize) {
        float: right;

        li {
          margin: 0 10px;

          button {
            .alt-text {
              display: none;
            }
          }
        }
      }
    }
  }

  .nav-center {
    background-color: $white;
    display: block;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    text-align: center;
    z-index: 1;

    ul {
      display: none;
      list-style: none;
      margin: 0;
      padding: 0;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);

      @media (min-width: $tabletSize) {
        display: block;
      }

      li {
        display: block;
        margin: 30px 0;

        a {
          font-size: 30px;
          font-weight: 900;
          text-transform: uppercase;

          span {
            border-bottom: none;

            &:before,
            &:after {
              height: 3px;
            }
          }
        }
      }
    }
  }

  li {
    border-bottom: 1px solid rgba($lightgray, .5);
    display: block;
    margin: 0;

    @media (min-width: $tabletSize) {
      border-bottom: none;
      display: inline-block;
      margin: 0 15px;
    }

    a,
    button {
      display: inline-block;
      padding: 10px 0;
      width: 100%;

      @media (min-width: $tabletSize) {
        padding: 0;
      }

    }

    a {
      &.active {
        color: $red;

        @media (min-width: $tabletSize) {
          span {
            border-bottom: 2px solid $red;
          }
        }
      }

      @media (min-width: $tabletSize) {
        span {
          position: relative;
          display: block;

          &:before,
          &:after {
            content: '';
            position: absolute;
            width: 0;
            height: 1.5px;
            top: 50%;
            margin-top: -.5px;
            background: $red;
          }

          &:before {
            left: -2.5px;
          }

          &:after {
            right: 2.5px;
            background: $red;
            transition: width .8s cubic-bezier(.22, .61, .36, 1);
          }

          &:hover {
            &:before {
              background: $red;
              width: 100%;
              transition: width .5s cubic-bezier(.22, .61, .36, 1);
            }

            &:after {
              background: transparent;
              width: 100%;
              transition: 0s;
            }
          }
        }
      }
    }
  }
}

.navbar-container {
  &.invert {
    .navbar {
      .logo {
        .logo-white {
          display: block;
        }

        .logo-black {
          display: none;
        }
      }

      &.active {
        .logo {
          .logo-white {
            display: none;
          }

          .logo-black {
            display: block;
          }
        }
      }

      .menu-toggler {
        div {
          background-color: $white;
        }
  
        &.active {
          div {
            background-color: $black;
          }
        }
      }

      .nav-left {
        li {
          a {
            @media (min-width: $tabletSize) {
              color: $white;
            }
          }
        }
      }

      .nav-right {
        li {
          button {
            color: $white;
  
            img {
              filter: invert(1);
            }
          }
        }

        &.active {
          li {
            button {
              color: $black;

              img {
                filter: none;
              }
            }
          }
        }
      }
    }
  }
}
