@import '../../assets/css/colors';
@import '../../assets/css/layout';

.featured-blog-entry-container {
  padding-top: 130%;
  position: relative;
  z-index: 1;

  @media (min-width: $tabletSize) {
    padding-top: 65%;
  }

  .featured-blog-entry-text {
    color: $white;
    position: absolute;
    left: 30px;
    right: 30px;
    bottom: 30px;

    @media (min-width: $tabletSize) {
      left: 60px;
      right: 60px;
      bottom: 60px;
    }
  }
}
