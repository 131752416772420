@import "colors";

// Small devices (landscape phones, 576px and up)
// @media (min-width: $mobileSize) { ... }

// Medium devices (tablets, 768px and up)
// @media (min-width: $tabletSize) { ... }

// Large devices (desktops, 992px and up)
// @media (min-width: $desktopSize) { ... }

// Extra large devices (large desktops, 1200px and up)
// @media (min-width: $largeDesktopSize) { ... }

$mobileSize: 576px;
$tabletSize: 768px;
$desktopSize: 992px;
$largeDesktopSize: 1200px;

::selection {
  background: rgba($green, 0.5);
}

::-webkit-scrollbar {
  width: 2px;
  height: 2px;
}

::-webkit-scrollbar-track {
  background: $lightgray;
}

::-webkit-scrollbar-thumb {
  background: $red;
}

html,
body {
  background-color: $whitesmoke;
  cursor: url(../../assets/icon/cursor.svg) 20 20, auto;
  margin: 0;
  padding: 0;
  overflow-x: hidden;
}

.fullscreen {
  position: relative;
  width: 100%;
  height: 100vh;
}

.full-cover {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  &.overlay {
    background-color: rgba($black, 0.2);
  }
}

.center-parent {
  position: relative;
  width: 100%;
  height: 100%;

  .center-child {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

.clear {
  clear: both;
}

.float-right {
  float: right;
}

.float-left {
  float: left;
}

.space-smaller {
  margin-top: 15px;
  margin-bottom: 15px;
}

.space {
  margin-top: 30px;
  margin-bottom: 30px;
}

.space-bigger {
  margin-top: 60px;
  margin-bottom: 60px;
}

.card {
  background-color: $white;
  border: none;
  border-radius: 15px;
  box-shadow: 0 10px 40px 0 rgba($black, 0.2);
  display: block;
  padding: 20px 30px;
}

.block {
  display: block;
}

.inline {
  display: inline;
}

.inline-block {
  display: inline-block;
}

.boxes {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  .box {
    background-color: $darkwhitesmoke;
    position: relative;
    width: 100%;
    height: 100vw;

    @media (min-width: $tabletSize) {
      width: 50%;
      height: 50vw;
    }

    .box-inner {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }
}

.page-margin {
  margin: 80px 0;

  &.large {
    margin: 140px 0;
  }
}

.background-image {
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;

  &.contain {
    background-size: contain;
  }

  &.landscape {
    padding-top: 65%;
  }

  &.square {
    padding-top: 100%;
  }

  &.portrait {
    padding-top: 130%;
  }

  &.panorama {
    padding-top: 50vh;
  }
}

@media (min-width: $tabletSize) {
  .vertical-center-on-tablet {
    transform: translateY(50%);
  }

  .center-on-tablet {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
  }
}
