@import '../../assets/css/layout';

.blog-thumbnail {
  .blog-thumbnail-image {
    cursor: url(../../assets/icon/cursor-image.svg) 20 20, auto;
    padding-top: 120%;

    @media (min-width: $tabletSize) {
      padding-top: 100%;
    }
  }

  .blog-thumbnail-text {
    margin-top: 30px;

    @media (min-width: $tabletSize) {
      margin-top: 0;
    }

    .btn {
      margin-left: 60px;
    }
  }
}
