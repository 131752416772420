@import '../../assets/css/colors';
@import '../../assets/css/layout';

.header {
  position: relative;
  width: 100%;
  height: 100vh;
  min-height: 660px;

  @media (min-width: $desktopSize) {
    height: 90vh;
  }

  .header-image {
    z-index: 0;

    @media (min-width: $desktopSize) {
      left: 30%;
    }
  }

  .header-text {
    @media (min-width: $tabletSize) {
      right: 40%;
    }

    @media (min-width: $desktopSize) {
      right: 50%;
    }

    @media (min-width: $largeDesktopSize) {
      right: 55%;
    }

    .header-text-inner {
      position: absolute;
      top: 50%;
      left: 10%;
      right: 10%;
      transform: translateY(-50%);

      @media (min-width: $tabletSize) {
        left: 20%;
      }

      @media (min-width: $desktopSize) {
        left: 30%;
      }

      @media (min-width: $largeDesktopSize) {
        left: 40%;
      }

      h1 {
        text-align: center;
      }

      p {
        margin: 40px auto;
        width: 70%;
      }

      .btn {
        position: relative;
        left: 10%;
      }
    }
  }
}
