// Colors
$black: #000000;
$white: #FFFFFF;
$whitesmoke: #F9F9F9;
$darkwhitesmoke: #F2F2F2;
$lightgray: #C4C4C4;
$darkgray: #747474;
$green: #2edb8e;
$red: #DB3641;
$blue: #0085ff;
$yellow: #f6cd3b;
