@import '../../assets/css/colors';
@import '../../assets/css/layout';

.about-slide {
  .arrow {
    background: none;
    border: none;
    padding: 0;
    margin-top: 60px;
  }

  .about-slide-text {
    position: absolute;
    top: 50%;
    left: 30px;
    width: 50%;
    transform: translateY(-50%);

    @media (min-width: $tabletSize) {
      width: 40%;
    }
  }

  .about-slide-readmore {
    position: absolute;
    left: 30px;
    bottom: 60px;
  }

  .about-slide-preview {
    display: none;

    @media (min-width: $tabletSize) {
      background-color: $white;
      display: block;
      position: absolute;
      top: 50%;
      right: 0;
      width: 40%;
      height: 50%;
      transform: translateY(-50%);

      .left-side {
        position: absolute;
        top: 0;
        left: 0;
        right: 45%;
        bottom: 0;

        .left-side-inner {
          position: absolute;
          top: 50%;
          left: 50%;
          width: 40%;
          min-height: 40%;
          transform: translate(-50%, -50%);

          .arrow {
            position: absolute;
            bottom: 0;
          }
        }
      }

      .right-side {
        position: absolute;
        top: 0;
        left: 55%;
        right: 0;
        bottom: 0;
      }
    }

    @media (min-width: $desktopSize) {
      width: 30%;
    }

    @media (min-width: $largeDesktopSize) {
      width: 25%;
    }
  }
  
  .about-slide-arrows {
    filter: invert(1);
    position: absolute;
    right: 30px;
    bottom: 60px;

    .arrow:nth-child(2) {
      margin-left: 30px;
    }
  }
}