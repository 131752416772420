@import '../../assets/css/layout';

.featured {
  .featured-text {
    position: relative;
    top: -20%;
    width: 100%;
    height: 100%;
    min-height: 250px;

    @media (min-width: $tabletSize) {
      top: 0;
    }

    .featured-text-inner {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);

      .icon {
        position: relative;
        top: -5px;
      }
    }
  }
}
