.hidden {
  opacity: 0;
}

// =========================================
// Fade
// =========================================

.fade-enter {
  opacity: 0;
}

.fade-enter-active {
  opacity: 1;
  transition: opacity .3s ease;
}

.fade-enter-done {
  opacity: 1;
}

.fade-exit {
  opacity: 1;
}

.fade-exit-active {
  opacity: 0;
  transition: opacity .3s ease;
}

.fade-exit-done {
  opacity: 0;
}

// =========================================
// Fade slow
// =========================================

.fadeSlow-enter {
  opacity: 0;
}

.fadeSlow-enter-active {
  opacity: 1;
  transition: opacity 1s ease;
}

.fadeSlow-exit {
  opacity: 1;
}

.fadeSlow-exit-active {
  opacity: 0;
  transition: opacity 1s ease;
}

// =========================================
// Fade up
// =========================================

.fadeUp-enter {
  opacity: 0;
  transform: translate3d(0, 20px, 0);
}

.fadeUp-enter-active {
  opacity: 1;
  transform: translate3d(0, 0, 0);
  transition: all .3s ease;
}

.fadeUp-exit {
  opacity: 1;
  transform: translate3d(0, 0, 0);
}

.fadeUp-exit-active {
  opacity: 0;
  transform: translate3d(0, 20px, 0);
  transition: all .3s ease;
}

// =========================================
// Fade up slow
// =========================================

.fadeUpSlow-enter {
  opacity: 0;
  transform: translate3d(0, 20px, 0);
}

.fadeUpSlow-enter-active {
  opacity: 1;
  transform: translate3d(0, 0, 0);
  transition: all 1s ease;
}

.fadeUpSlow-exit {
  opacity: 1;
  transform: translate3d(0, 0, 0);
}

.fadeUpSlow-exit-active {
  opacity: 0;
  transform: translate3d(0, 20px, 0);
  transition: all 1s ease;
}

// =========================================
// Perspective up
// =========================================

.perspectiveUp-enter-active,
.perspectiveUp-enter-done,
.perspectiveUp-exit {
  opacity: 1;
  transform: translate3d(0, 0, 0) perspective(0px) rotatex(0deg);
  transition: all .2s ease-out;
}

.perspectiveUp-enter,
.perspectiveUp-exit-active {
  opacity: 0;
  transform: translate3d(0, 30px, 0) perspective(100px) rotatex(-1deg);
}
